/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from "react";

import clsx from "clsx";

import {
  useRouteMatch,
  NavLink,
  Switch,
  Route,
  Link,
  useLocation,
  Redirect,
} from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { QUERY_CLIENT } from "../../../config/graphql/query";
import { NEDAP_CLIENTS } from "../../../config/const/common";

import Tokens from "./Token/Tokens";
import Preferences from "./Preferences";

import OutlookIntegrations from "./Outlook/Integrations";
import ActiveDirectoryIntegrations from "./ActiveDirectory/Integrations";

import TwilioClients, { useQueryTwilioClients } from "./Twilio/TwilioClients";

import InformationForm from "./Information/Information";
import SendGridClients, { useQuerySendGridClients } from "./SendGrid/Clients";
import EmailTemplates from "./Email/EmailTemplates";
import MessageTemplates from "./Message/MessageTemplates";

import NedapIntegration from "./Nedap/Integration";

import { useClientId } from "./hooks/useClient";
import { useHasPlan } from "../../../components/PlanBlock";

import useHasRole from "../../../lib/hooks/useHasRole";
import TeamsIntegration from "./Teams/Integration";

export const ClientRoute = React.memo(() => {
  const { t } = useTranslation(["client", "common"]);

  const clientId = useClientId();

  const match = useRouteMatch();
  const location = useLocation();

  const { data: clientData } = useQuery<{ client: IClient }>(QUERY_CLIENT, {
    variables: { id: clientId },
    nextFetchPolicy: "network-only",
  });

  const isAdmin = useHasRole(["ADMIN"]);
  const isLowerThanClient = useHasRole(["USER", "TORO1"]);
  const isLowerThanEnterprise = useHasPlan(clientData?.client, [
    "free",
    "basic",
  ]);
  const hasNedap = NEDAP_CLIENTS.includes(clientData?.client?.code ?? "");

  const { data: twilioClientsData } = useQueryTwilioClients();
  const { data: sendGridClientsData } = useQuerySendGridClients();

  const hasTwilioClient = useMemo(
    () =>
      twilioClientsData?.twilioClients &&
      Array.isArray(twilioClientsData?.twilioClients) &&
      twilioClientsData?.twilioClients.length > 0,
    [twilioClientsData?.twilioClients],
  );

  const hasSendGridClient = useMemo(
    () =>
      sendGridClientsData?.sendGridClients &&
      Array.isArray(sendGridClientsData?.sendGridClients) &&
      sendGridClientsData?.sendGridClients.length > 0,
    [sendGridClientsData?.sendGridClients],
  );

  return (
    <>
      <ul className="nav nav-tabs mb-4">
        <li className="nav-item">
          <NavLink exact className="nav-link" to={match.url}>
            {t("client:client.clientCreate.nav.information")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={clsx("nav-link", {
              disabled: !clientId,
            })}
            to={`${match.url}/preferences`}
          >
            {t("client:client.clientCreate.nav.preferences")}
          </NavLink>
        </li>
        {isAdmin && (
          <li className="nav-item">
            <NavLink
              className={clsx("nav-link", {
                disabled: !clientId,
              })}
              to={`${match.url}/token`}
            >
              {t("client:client.clientCreate.nav.tokens")}
            </NavLink>
          </li>
        )}
        <li className="nav-item">
          <NavLink
            className={clsx("nav-link", {
              disabled: !clientId || isLowerThanEnterprise || isLowerThanClient,
            })}
            to={`${match.url}/twilio${!isAdmin ? "-message-templates" : ""}`}
            isActive={() => {
              return location.pathname.includes("twilio");
            }}
          >
            {t("client:client.clientCreate.nav.twilio") +
              (!isAdmin
                ? ` ${t("client:client.sendgrid.nav.message-templates")}`
                : "")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={clsx("nav-link", {
              disabled: !clientId || isLowerThanEnterprise || isLowerThanClient,
            })}
            to={`${match.url}/integrations/outlook`}
          >
            {t("client:client.clientCreate.nav.integrations.outlook")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={clsx("nav-link", {
              disabled: !clientId || isLowerThanEnterprise || isLowerThanClient,
            })}
            to={`${match.url}/integrations/activedirectory`}
          >
            {t("client:client.clientCreate.nav.activeDirectory")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={clsx("nav-link", {
              disabled: !clientId || isLowerThanEnterprise || isLowerThanClient,
            })}
            to={`${match.url}/sendgrid${!isAdmin ? "-email-templates" : ""}`}
            isActive={() => {
              return location.pathname.includes("sendgrid");
            }}
          >
            {t("client:client.clientCreate.nav.sendgrid") +
              (!isAdmin ? ` ${t("client:client.sendgrid.nav.templates")}` : "")}
          </NavLink>
        </li>
        {!isLowerThanEnterprise && !isLowerThanClient && hasNedap && (
          <li className="nav-item">
            <NavLink
              className={clsx("nav-link", {
                disabled:
                  !clientId || isLowerThanEnterprise || isLowerThanClient,
              })}
              to={`${match.url}/nedap`}
              isActive={() => !!location.pathname.includes("nedap")}
            >
              {t("client:client.clientCreate.nav.nedap")}
            </NavLink>
          </li>
        )}
        <li className="nav-item">
          <NavLink
            className={clsx("nav-link", {
              disabled: !clientId || isLowerThanEnterprise || isLowerThanClient,
            })}
            to={`${match.url}/integrations/teams`}
          >
            {t("client:client.clientCreate.nav.teams")}
          </NavLink>
        </li>
      </ul>

      <div className="tab-content">
        {hasSendGridClient && (
          <Route
            path={[
              `${match.path}/sendgrid`,
              `${match.path}/sendgrid-email-templates`,
            ]}
          >
            <ul className="nav nav-tabs mb-4">
              <li className="nav-item">
                <NavLink
                  exact
                  className={clsx("nav-link", {
                    disabled: !isAdmin,
                  })}
                  to={`${match.url}/sendgrid`}
                >
                  {t("client:client.sendgrid.nav.apikeys")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to={`${match.url}/sendgrid-email-templates`}
                >
                  {t("client:client.sendgrid.nav.templates")}
                </NavLink>
              </li>
            </ul>
          </Route>
        )}
        {hasTwilioClient && (
          <Route
            path={[
              `${match.path}/twilio`,
              `${match.path}/twilio-message-templates`,
            ]}
          >
            <ul className="nav nav-tabs mb-4">
              <li className="nav-item">
                <NavLink
                  exact
                  className={clsx("nav-link", {
                    disabled: !isAdmin,
                  })}
                  to={`${match.url}/twilio`}
                >
                  {t("client:client.sendgrid.nav.apikeys")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to={`${match.url}/twilio-message-templates`}
                >
                  {t("client:client.sendgrid.nav.message-templates")}
                </NavLink>
              </li>
            </ul>
          </Route>
        )}

        <Switch>
          <Route exact path={match.path}>
            <div className="tab-pane active" role="tabpanel">
              <InformationForm />
            </div>
          </Route>

          {!isLowerThanEnterprise && !isLowerThanClient && (
            <Route exact path={`${match.path}/integrations/outlook`}>
              <div className="tab-pane active" role="tabpanel">
                <OutlookIntegrations />
              </div>
            </Route>
          )}

          {!isLowerThanEnterprise && !isLowerThanClient && (
            <Route exact path={`${match.path}/integrations/activedirectory`}>
              <div className="tab-pane active" role="tabpanel">
                <ActiveDirectoryIntegrations />
              </div>
            </Route>
          )}

          <Route path={`${match.path}/preferences`}>
            <div className="tab-pane active" role="tabpanel">
              <Preferences />
            </div>
          </Route>

          {!isLowerThanEnterprise && !isLowerThanClient && hasNedap && (
            <Route path={`${match.path}/nedap`}>
              <div className="tab-pane active" role="tabpanel">
                <NedapIntegration />
              </div>
            </Route>
          )}

          {!isLowerThanEnterprise && !isLowerThanClient && (
            <Route exact path={`${match.path}/integrations/teams`}>
              <div className="tab-pane active" role="tabpanel">
                <TeamsIntegration />
              </div>
            </Route>
          )}

          {isAdmin && (
            <Route exact path={`${match.path}/token`}>
              <div className="tab-pane active" role="tabpanel">
                <Tokens />
              </div>
            </Route>
          )}

          {isAdmin && !isLowerThanEnterprise && (
            <Route exact path={`${match.path}/twilio`}>
              <div className="tab-pane active" role="tabpanel">
                <TwilioClients />
              </div>
            </Route>
          )}
          {isAdmin && !isLowerThanEnterprise && (
            <Route exact path={`${match.path}/sendgrid`}>
              <div className="tab-pane active" role="tabpanel">
                <SendGridClients />
              </div>
            </Route>
          )}
          {!isLowerThanEnterprise && !isLowerThanClient && (
            <Route path={`${match.path}/sendgrid-email-templates`}>
              <div className="tab-pane active" role="tabpanel">
                <EmailTemplates />
              </div>
            </Route>
          )}
          {!isLowerThanEnterprise && !isLowerThanClient && (
            <Route path={`${match.path}/twilio-message-templates`}>
              <div className="tab-pane active" role="tabpanel">
                <MessageTemplates />
              </div>
            </Route>
          )}

          <Redirect
            from={`${match.path}/integration`}
            to={`${match.path}/integrations/outlook`}
          />
        </Switch>
      </div>
    </>
  );
});

const ClientPage = () => {
  const { t } = useTranslation(["client", "common"]);

  const clientId = useClientId();

  const { data } = useQuery<{ client: IClient }>(QUERY_CLIENT, {
    variables: { id: clientId },
    nextFetchPolicy: "network-only",
    skip: !clientId,
  });

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to="/clients">
              {t("client:client.clientCreate.nav.clients")}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {data?.client?.title ?? "Client"}
          </li>
        </ol>
      </nav>
      <ClientRoute />
    </div>
  );
};

export const ClientCreatePage = () => {
  const { t } = useTranslation(["client", "common"]);

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to="/clients">
              {t("client:client.clientCreate.nav.clients")}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {t("client:client.clientCreate.nav.client")}
          </li>
        </ol>
      </nav>
      <InformationForm />
    </div>
  );
};

export default ClientPage;
