/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useToggle } from "react-use";

import map from "lodash/map";
import intersectionWith from "lodash/intersectionWith";
import { useTranslation } from "react-i18next";

import { useMutation, useQuery } from "@apollo/client";

import { toast } from "react-toastify";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";

import {
  QUERY_PROPERTY_COMPANY,
  QUERY_ACTIVE_DIRECTORIES,
  QUERY_ACTIVE_DIRECTORY_GROUPS,
  QUERY_ACTIVE_DIRECTORIES_SYNC,
  QUERY_ACTIVE_DIRECTORIES_SYNCS,
  QUERY_MEMBER_GROUPS,
} from "../../../../config/graphql/query";
import {
  MUTATION_CREATE_ACTIVE_DIRECTORY_SYNC,
  MUTATION_DELETE_ACTIVE_DIRECTORY_SYNC,
  MUTATION_UPDATE_ACTIVE_DIRECTORY_SYNC,
} from "../../../../config/graphql/mutation";
import RoleBlock from "../../../../components/RoleBlock";

type FieldValues = {
  company?: Pick<ICompany, "id" | "title">;
  activeDirectory?: Pick<IActiveDirectory, "id" | "email" | "title">;
  group?: IActiveDirectoryGroup;
  memberGroups?: IMemberGroup[];
  settings: IActiveDirectorySyncSettings;
};

const useRouteParams = () => {
  return useParams<{
    companyId: string;
    integrationId?: string;
  }>();
};

const useQueryCompany = () => {
  const { companyId } = useRouteParams();

  return useQuery<{ property: ICompany }>(QUERY_PROPERTY_COMPANY, {
    variables: { id: companyId },
  });
};

const BreadcrumbsCompany = () => {
  const { t } = useTranslation(["company"]);

  const { companyId } = useRouteParams();

  const { data: companyData } = useQueryCompany();

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb my-3">
        <li className="breadcrumb-item">
          <Link to="/company">{t("company:nav.companies")}</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to={`/company/${companyId}`}>
            {companyData?.property?.title ?? t("company:nav.company")}
          </Link>
        </li>
        <li className="breadcrumb-item">
          <Link to={`/company/${companyId}/integrations`}>
            {t("company:nav.integrations")}
          </Link>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {t("company:nav.activeDirectorySync")}
        </li>
      </ol>
    </nav>
  );
};

const ActiveDirectoryGroupSelect = () => {
  const { t } = useTranslation(["activeDirectory"]);

  const methods = useFormContext();

  const activeDirectory = methods.watch("activeDirectory");

  const [search, setSearch] = useState("");

  const { data: activeDirectoryGroupsData, loading: isLoadingGroups } =
    useQuery<{
      fetchActiveDirectoryGroups: IActiveDirectoryGroup[];
    }>(QUERY_ACTIVE_DIRECTORY_GROUPS, {
      variables: {
        filter: {
          id: activeDirectory?.id,
          displayName: search,
        },
      },
      skip: !activeDirectory?.id,
      onError: (error: any) => {
        const errorMessage =
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message;

        methods.setError("group", { message: errorMessage });
      },
    });

  return (
    <div className="form-group">
      <label>{t("activeDirectorySync:input:label.group")}</label>
      <Controller
        name="group"
        render={({ field: { onChange, ...props }, fieldState: { error } }) => {
          const options =
            activeDirectoryGroupsData?.fetchActiveDirectoryGroups ?? [];

          return (
            <>
              <Select
                getOptionLabel={({ displayName }) => displayName}
                getOptionValue={({ id }) => id}
                options={options}
                isDisabled={!activeDirectory}
                isClearable
                isLoading={isLoadingGroups}
                onChange={onChange}
                onInputChange={(value) => setSearch(value)}
                {...props}
              />
              {!!error && (
                <div className="invalid-feedback">{error?.message}</div>
              )}
            </>
          );
        }}
      />
      <small id="properties-help" className="form-text text-muted small">
        {t("activeDirectorySync:input:helper.group")}
      </small>
    </div>
  );
};

const ActiveDirectorySync = React.memo(() => {
  const history = useHistory();

  const { t } = useTranslation(["activeDirectorySync"]);

  const [show, setShow] = useToggle(false);

  const [keepEmployees, setKeepEmployees] = useState<boolean>(false);

  const { integrationId, companyId } = useRouteParams();

  const methods = useForm<FieldValues>();

  const { data: companyData } = useQueryCompany();

  const { data: groupsData } = useQuery<{
    memberGroups: IMemberGroup[];
  }>(QUERY_MEMBER_GROUPS, {
    fetchPolicy: "network-only",
  });

  const { data: activeDirectorySyncData } = useQuery<{
    activeDirectorySync: IActiveDirectorySync;
  }>(QUERY_ACTIVE_DIRECTORIES_SYNC, {
    variables: { id: integrationId },
    skip: !integrationId,
    onError: () => {
      history.replace(`/company/${companyId}/integrations/activedirectory`);
    },
  });

  useEffect(() => {
    if (companyData?.property && !integrationId) {
      methods.setValue("company", companyData?.property);
    }
  }, [companyData, methods]);

  const { data: activeDirectoriesData, loading: isLoadingActiveDirectories } =
    useQuery<{
      activeDirectories: IActiveDirectory[];
    }>(QUERY_ACTIVE_DIRECTORIES);

  useEffect(() => {
    if (activeDirectoriesData?.activeDirectories && !integrationId) {
      methods.setValue(
        "activeDirectory",
        activeDirectoriesData?.activeDirectories?.[0],
      );
    }
  }, [
    activeDirectoriesData?.activeDirectories,
    companyData,
    integrationId,
    methods,
  ]);

  useEffect(() => {
    if (activeDirectorySyncData) {
      methods.reset(activeDirectorySyncData?.activeDirectorySync);
    }
  }, [activeDirectorySyncData, methods]);

  const [onCreate, { loading: isCreating }] = useMutation<{
    createActiveDirectorySync: IActiveDirectorySync;
  }>(MUTATION_CREATE_ACTIVE_DIRECTORY_SYNC, {
    refetchQueries: [{ query: QUERY_ACTIVE_DIRECTORIES_SYNCS }],
  });

  const [onUpdate, { loading: isUpdating }] = useMutation<{
    updateActiveDirectorySync: IActiveDirectorySync;
  }>(MUTATION_UPDATE_ACTIVE_DIRECTORY_SYNC, {
    refetchQueries: [{ query: QUERY_ACTIVE_DIRECTORIES_SYNCS }],
  });

  const [onDelete, { loading: isDeleting }] = useMutation<{
    deleteActiveDirectorySync: IActiveDirectorySync;
  }>(MUTATION_DELETE_ACTIVE_DIRECTORY_SYNC, {
    refetchQueries: [{ query: QUERY_ACTIVE_DIRECTORIES_SYNCS }],
    awaitRefetchQueries: true,
  });

  const onSubmit = (variables: FieldValues) => {
    if (integrationId) {
      return onUpdate({
        variables: {
          input: {
            id: integrationId,
            groupId: variables.group?.id ?? null,
            memberGroups: variables.memberGroups?.map(({ id }) => id) ?? [],
            settings: {
              syncFields: {
                phone: {
                  enabled:
                    variables?.settings?.syncFields?.phone?.enabled ?? false,
                  type:
                    variables?.settings?.syncFields?.phone?.type?.map(
                      (type) => type,
                    ) ?? [],
                },
                skipAccess: variables?.settings?.syncFields?.skipAccess,
              },
            },
          },
        },
      })
        .then(() => {
          toast.success<string>(t("activeDirectorySync:toast.updateSuccess"));
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    }

    return onCreate({
      variables: {
        input: {
          activeDirectory: variables?.activeDirectory?.id,
          company: variables?.company?.id,
          groupId: variables.group?.id,
          memberGroups: variables.memberGroups?.map(({ id }) => id) ?? [],
          settings: {
            syncFields: {
              phone: {
                enabled:
                  variables?.settings?.syncFields?.phone?.enabled ?? false,
                type:
                  variables?.settings?.syncFields?.phone?.type?.map(
                    (type) => type,
                  ) ?? [],
              },
              skipAccess: variables?.settings?.syncFields?.skipAccess,
            },
          },
        },
      },
    })
      .then(({ data }) => {
        toast.success<string>(t("activeDirectorySync:toast.createSuccess"));

        return history.push(
          `activedirectory/${data?.createActiveDirectorySync?.id}`,
        );
      })
      .catch((error) => {
        toast.error<string>(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
        );
      });
  };

  const onBeforeRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      setShow(true);
    },
    [setShow],
  );

  const onRemoveActiveDirectory = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      return onDelete({
        variables: {
          input: {
            id: integrationId,
            keepEmployees: false,
          },
        },
      })
        .then(() => {
          history.replace(`/company/${companyId}/integrations`);
          toast.success<string>(t("company:information.toast.deleted"));
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    },
    [history, onDelete, integrationId, t],
  );

  const phoneTypes = useMemo(
    () => [
      {
        title: t("activeDirectorySync:input:option.phoneType.business"),
        type: "businessPhones",
      },
      {
        title: t("activeDirectorySync:input:option.phoneType.mobile"),
        type: "mobilePhone",
      },
    ],
    [t],
  );

  return (
    <div className="container-fluid">
      <BreadcrumbsCompany />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-12 d-flex">
            <div className="col-lg-4 col-md-6 col-sm-12 row mr-3">
              <h6>{t(`activeDirectorySync:settings.details`)}</h6>
              <div className="w-100">
                <div className="form-group">
                  <label htmlFor="title">
                    {t("activeDirectorySync:input:label.company")}
                  </label>
                  <Controller
                    name="company"
                    render={({ field: { onChange, ...props } }) => (
                      <Select
                        getOptionLabel={({ title }) => title}
                        getOptionValue={({ id }) => id}
                        options={[companyData?.property]}
                        onChange={(value) => onChange(value)}
                        isDisabled
                        {...props}
                      />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: t(
                          "activeDirectorySync:input:error.company.required",
                        ),
                      },
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="activeDirectory">
                    {t("activeDirectorySync:input:label.activeDirectory")}
                  </label>
                  <Controller
                    name="activeDirectory"
                    render={({ field: { onChange, ...props } }) => (
                      <Select
                        getOptionLabel={({ name, email }) =>
                          [name, email].filter(Boolean).join(" - ")
                        }
                        getOptionValue={({ id }) => id}
                        isDisabled={!!integrationId}
                        isLoading={isLoadingActiveDirectories}
                        options={activeDirectoriesData?.activeDirectories ?? []}
                        onChange={(value) => onChange(value)}
                        {...props}
                      />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: t(
                          "activeDirectorySync:input:error.activeDirectory.required",
                        ),
                      },
                    }}
                  />
                </div>
                <ActiveDirectoryGroupSelect />
                <div className="form-group">
                  <label htmlFor="memberGroups">
                    {t("activeDirectorySync:input:label.employeeGroup")}
                  </label>
                  <Controller
                    name="memberGroups"
                    render={({ field: { onChange, ...props } }) => (
                      <Select
                        getOptionLabel={({ title }) => title}
                        getOptionValue={({ id }) => id}
                        options={groupsData?.memberGroups ?? []}
                        onChange={(value) => onChange(value)}
                        isMulti
                        closeMenuOnSelect={false}
                        {...props}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 row">
              <div className="w-100">
                <h6>{t(`activeDirectorySync:settings.title`)}</h6>
                <div className="w-100">
                  <div className="form-group">
                    <label>
                      {t("activeDirectorySync:input:label.syncPropertiesTitle")}
                    </label>
                    <Controller
                      name="settings.syncFields.phone.enabled"
                      defaultValue={false}
                      render={({
                        field: { value, name, onChange },
                        fieldState: { error },
                      }) => (
                        <div className="form-group form-check mb-2">
                          <input
                            id={name}
                            type="checkbox"
                            className="form-check-input"
                            checked={value}
                            onChange={onChange}
                          />
                          <label
                            className="form-check-label user-select-none"
                            htmlFor={name}
                          >
                            {t(
                              `activeDirectorySync:input:label.settings.phone.enabled`,
                            )}
                          </label>
                          {!!error && (
                            <div className="invalid-feedback">
                              {error.message}
                            </div>
                          )}
                        </div>
                      )}
                    />
                    <Controller
                      name="settings.syncFields.phone.type"
                      render={({
                        field: { onChange, value: inputValue, ...props },
                      }) => {
                        const value = intersectionWith(
                          phoneTypes,
                          inputValue,
                          (option, value) => option.type === value,
                        );

                        return (
                          <Select
                            getOptionLabel={({ title }) => title}
                            getOptionValue={({ type }) => type}
                            options={phoneTypes}
                            onChange={(value) =>
                              onChange(map(value, (value) => value.type))
                            }
                            isMulti
                            value={value}
                            closeMenuOnSelect={false}
                            isDisabled={
                              !methods.watch(
                                "settings.syncFields.phone.enabled",
                              )
                            }
                            {...props}
                          />
                        );
                      }}
                    />
                    <small className="form-text text-muted">
                      {t(`activeDirectorySync:settings.phone.subtitle`)}
                    </small>
                  </div>
                </div>
                <div className="w-100">
                  <div className="form-group">
                    <Controller
                      name="settings.syncFields.skipAccess"
                      defaultValue={false}
                      render={({
                        field: { value, name, onChange },
                        fieldState: { error },
                      }) => (
                        <div className="form-group form-check mb-2">
                          <input
                            id={name}
                            type="checkbox"
                            className="form-check-input"
                            checked={value}
                            onChange={onChange}
                          />
                          <label
                            className="form-check-label user-select-none"
                            htmlFor={name}
                          >
                            {t(
                              `activeDirectorySync:input:label.settings.skipAccess`,
                            )}
                          </label>
                          {!!error && (
                            <div className="invalid-feedback">
                              {error.message}
                            </div>
                          )}
                        </div>
                      )}
                    />
                  </div>
                </div>
                <small className="form-text text-muted small">
                  {t(`activeDirectorySync:settings.subtitle`)}
                </small>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <input
                type="submit"
                className="btn btn-primary"
                disabled={isCreating || isUpdating}
                value={t("activeDirectorySync:button.create")}
              />
              <RoleBlock roles={["ADMIN", "CLIENT_ADMIN"]}>
                {integrationId && (
                  <button
                    onClick={onBeforeRemove}
                    className="btn btn-danger ml-3"
                    disabled={isUpdating || isDeleting}
                  >
                    {t("common:delete")}
                  </button>
                )}
              </RoleBlock>
            </div>
          </div>
        </form>
      </FormProvider>
      <Modal show={show} onHide={setShow} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("company:integrations.activeDirectory.modal.delete.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group form-check mb-3">
            <input
              id="keepEmployees"
              type="checkbox"
              className="form-check-input"
              checked={keepEmployees}
              onChange={(e) => {
                setKeepEmployees(e.target.checked);
              }}
            />
            <label
              className="form-check-label user-select-none"
              htmlFor="keepEmployees"
            >
              {t(
                "company:integrations.activeDirectory.modal.delete.keepEmployees",
              )}
            </label>
          </div>
          <div>
            {t("company:integrations.activeDirectory.modal.delete.body")}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={setShow}>
            {t(
              "company:integrations.activeDirectory.modal.delete.button.dismiss",
            )}
          </Button>
          <Button variant="danger" onClick={onRemoveActiveDirectory}>
            {t(
              "company:integrations.activeDirectory.modal.delete.button.submit",
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default ActiveDirectorySync;
