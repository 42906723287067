import React, { useMemo, useCallback } from "react";
import qs from "query-string";
import dayjs from "dayjs";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import useToggle from "react-use/lib/useToggle";

import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";

import { QUERY_MS_TEAMS } from "../../../../config/graphql/query";
import { MUTATION_DELETE_MS_TEAMS_INTEGRATION } from "../../../../config/graphql/mutation";

import { useClientId } from "../hooks/useClient";

const TeamsIntegration = React.memo(() => {
  const [show, setShow] = useToggle(false);
  const { t } = useTranslation(["client", "common"]);

  const clientId = useClientId();

  const [onDelete] = useMutation(MUTATION_DELETE_MS_TEAMS_INTEGRATION, {
    refetchQueries: [
      {
        query: QUERY_MS_TEAMS,
      },
    ],
  });

  const { data: teamsData, loading } = useQuery<{ teams: ITeams }>(
    QUERY_MS_TEAMS,
  );

  const hasTeamsIntegration = useMemo(
    () => !loading && !!teamsData?.teams,
    [teamsData, loading],
  );

  const teamsId = useMemo(
    () => !loading && teamsData?.teams?.id,
    [teamsData, loading],
  );

  const teamsCaller = useMemo(
    () => !loading && teamsData?.teams?.caller,
    [teamsData, loading],
  );

  const onRemoveClientIntegration = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      return onDelete({ variables: { id: teamsId } })
        .then(() => {
          toast.success<string>(
            t("client:client.integrationForm.toast.deleted"),
          );
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    },
    [teamsId, onDelete, t],
  );

  const onBeforeRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      setShow(true);
    },
    [setShow],
  );

  const integrationOAuthURL = (type: "integration" | "caller") =>
    qs.stringifyUrl(
      {
        url: `${process.env.REACT_APP_API_URL}/api/v1/teams/auth/login`,
        query: {
          client: clientId,
          type,
          tenant: teamsData?.teams?.tenantId,
          redirectUri: window.location.href,
        },
      },
      { encode: true },
    );

  if (!hasTeamsIntegration) {
    return (
      <div className="container-fluid">
        <div className="d-flex justify-content-end align-items-center my-4">
          <a
            href={integrationOAuthURL("integration")}
            className="btn btn-primary"
          >
            {t("client:client.integrations.teams.button.generate")}
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-end mb-3">
        <button onClick={onBeforeRemove} className="btn btn-danger">
          {t("client:client.integrations.teams.button.delete")}
        </button>
      </div>

      <Card className="mb-3">
        <Card.Header>
          {t("client:client.integrationForm.form.information")}
        </Card.Header>
        <Card.Body>
          {t("client:client.integrationForm.form.tenant")}
          {teamsData?.teams?.tenantId ?? ""}
          <hr />
          {t("client:client.integrationForm.form.created")}
          {dayjs(teamsData?.teams?.createdAt).format("L LT") ?? ""}
        </Card.Body>
      </Card>

      {teamsCaller ? (
        <Card className="mb-3">
          <Card.Header>
            {t("client:client.integrationForm.form.caller")}
          </Card.Header>
          <Card.Body>
            {t("client:client.integrationForm.form.account")}
            {teamsCaller.homeAccountId ?? ""}
            <hr />
            {t("client:client.integrationForm.form.email")}
            {teamsCaller.email ?? ""}
            <hr />
            {t("client:client.integrationForm.form.name")}
            {teamsCaller.name ?? ""}
          </Card.Body>
        </Card>
      ) : (
        <div className="mx-2 ">
          {t("client:client.integrations.teams.callerInfo")}
        </div>
      )}

      <div className="mt-3">
        <a href={integrationOAuthURL("caller")} className="btn btn-primary">
          {teamsCaller
            ? t("client:client.integrations.teams.button.changeCaller")
            : t("client:client.integrations.teams.button.generateCaller")}
        </a>
      </div>

      <Modal show={show} onHide={setShow} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("client:client.integrations.teams.modal.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("client:client.integrations.teams.modal.body")}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={setShow}>
            {t("common:cancel")}
          </Button>
          <Button variant="danger" onClick={onRemoveClientIntegration}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default TeamsIntegration;
