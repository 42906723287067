/* eslint-disable */

import React, { useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import clsx from "clsx";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import { useMutation, useQuery } from "@apollo/client";
import {
  MUTATION_NEDAP_SYNC_DELETE,
  MUTATION_NEDAP_SYNC_SYNC,
} from "../../../../config/graphql/mutation";
import {
  QUERY_NEDAP_SYNC_LOCATIONS,
  QUERY_NEDAP_SYNC_LAST_SYNC,
  QUERY_PROPERTIES_NEDAP_RESIDENTS,
} from "../../../../config/graphql/query";
import { useClientId } from "../hooks/useClient";
import {
  INedapLocations,
  INedapResidents,
  INedapSync,
} from "../../../../@types/nedap";
import useHasRole from "../../../../lib/hooks/useHasRole";

const useNedapSyncMutation = (client: string) =>
  useMutation<
    INedapLocations,
    { input: { locations: number[]; client: string } }
  >(MUTATION_NEDAP_SYNC_SYNC, {
    context: {
      headers: {
        ClientId: client,
      },
    },
    refetchQueries: [
      {
        query: QUERY_NEDAP_SYNC_LAST_SYNC,
        variables: {
          input: {
            client,
          },
        },
      },
    ],
  });

const useNedapSyncQuery = (client: string) =>
  useQuery<INedapSync>(QUERY_NEDAP_SYNC_LAST_SYNC, {
    context: {
      headers: {
        ClientId: client,
      },
    },
    variables: {
      input: {
        client,
      },
    },
  });

const useNedapLocationsQuery = (client: string) =>
  useQuery<INedapLocations>(QUERY_NEDAP_SYNC_LOCATIONS, {
    context: {
      headers: {
        ClientId: client,
      },
    },
    variables: {
      input: {
        client,
      },
    },
  });

const useNedapResidentsQuery = (client: string) =>
  useQuery<INedapResidents>(QUERY_PROPERTIES_NEDAP_RESIDENTS, {
    context: {
      headers: {
        ClientId: client,
      },
    },
    variables: {
      filter: {
        type: "NedapResident",
      },
    },
  });

const useNedapDeleteSyncMutation = (client: string) =>
  useMutation<{ deleteNedapSync: boolean }, { input: { client: string } }>(
    MUTATION_NEDAP_SYNC_DELETE,
    {
      context: {
        headers: {
          ClientId: client,
        },
      },
      refetchQueries: [
        {
          query: QUERY_NEDAP_SYNC_LAST_SYNC,
          variables: {
            input: {
              client,
            },
          },
        },
      ],
    },
  );

const NedapIntegration = React.memo(() => {
  const clientId = useClientId();

  const { t, i18n } = useTranslation(["client", "common"]);

  const [syncNedap] = useNedapSyncMutation(clientId);
  const [deleteNedapSync] = useNedapDeleteSyncMutation(clientId);
  const { data: syncData } = useNedapSyncQuery(clientId);
  const { data: locationData } = useNedapLocationsQuery(clientId);
  const { data: nedapResidents } = useNedapResidentsQuery(clientId);

  const isAdmin = useHasRole(["ADMIN"]);

  const [isLoading, setIsLoading] = useState(false);

  const lastSyncDate = syncData?.nedapLastSync?.createdAt;
  const lastSyncLocations = syncData?.nedapLastSync?.locations;
  const locationOptions = useMemo(() => {
    return (
      locationData?.nedapLocations?.map((location) => ({
        value: location.id,
        label: `${location.name} (${location.id})`,
      })) || []
    );
  }, [locationData]);

  const schema = useMemo(
    () =>
      yup.object().shape({
        locations: yup
          .array(
            yup.object().shape({
              value: yup.number(),
              label: yup.string(),
            }),
          )
          .ensure()
          .required(t("client:client.nedap.yup.location")),
      }),
    [],
  );

  const methods = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    shouldUnregister: true,
    defaultValues: {
      locations: [] as { value: number; label: string }[],
    },
  });

  useEffect(() => {
    if (
      !lastSyncLocations ||
      lastSyncLocations?.join("|") ===
        methods
          .getValues("locations")
          .map((location) => location.value)
          .join("|")
    ) {
      return;
    }

    methods.setValue(
      "locations",
      locationOptions?.filter(
        (location) => lastSyncLocations?.includes(location.value) ?? [],
      ),
    );
  }, [lastSyncLocations, locationOptions]);

  useEffect(() => {
    if (!dayjs || !i18n?.language) {
      return;
    }

    dayjs.locale(i18n?.language);
  }, [i18n]);

  const onSubmit: SubmitHandler<{
    locations: {
      value: number;
      label: string;
    }[];
  }> = async (data) => {
    setIsLoading(true);
    try {
      const locations = data.locations.map((location) => location.value);

      const result = await syncNedap({
        variables: { input: { locations, client: clientId } },
      });

      console.log(result);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onDeleteSync = useCallback(async () => {
    setIsLoading(true);
    try {
      await deleteNedapSync({
        variables: { input: { client: clientId } },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [clientId, syncNedap]);

  return (
    <FormProvider {...methods}>
      <form
        className="row flex flex-column"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <div className="col-xl-6 col-lg-8 col-md-10 col-12">
          <Controller
            name="locations"
            render={({
              field: { value, onChange, ...field },
              fieldState: { error },
            }) => (
              <div className="form-group">
                <label htmlFor="type">
                  {t("client:client.nedap.form.location")}
                </label>
                <Select
                  {...field}
                  isLoading={isLoading}
                  isMulti
                  closeMenuOnSelect={false}
                  isClearable={false}
                  value={value}
                  options={locationOptions}
                  className={clsx({
                    "is-invalid": !!error,
                  })}
                  // styles={reactSelectCustomStyles(!!error)}
                  onChange={(options) => onChange(options)}
                />
                {!!error && (
                  <div className="invalid-feedback">{error?.message}</div>
                )}
              </div>
            )}
          />
        </div>
        <div className="col-xl-6 col-lg-8 col-md-10 col-12 d-flex flex-wrap justify-content-start align-items-start flex-column flex-sm-row align-items-sm-center">
          <input
            type="submit"
            disabled={isLoading}
            className="btn btn-primary mb-2 mr-2"
            value={t("client:client.nedap.form.submit")}
          />
          {lastSyncDate && (
            <button
              type="button"
              disabled={isLoading}
              className="btn btn-danger mb-2"
              onClick={onDeleteSync}
            >
              {t("client:client.nedap.form.removeSync")}
            </button>
          )}
          {lastSyncDate && (
            <p className="mb-0 ml-auto">
              Last sync: {dayjs(lastSyncDate).format("LLLL")}
            </p>
          )}
        </div>
        {!(nedapResidents?.properties?.length && isAdmin) ? null : (
          <div className="col-12 mt-3">
            <p>{t("client:client.nedap.residents.title")}</p>
            <ul>
              {nedapResidents?.properties?.map((resident) => {
                return (
                  <li key={resident.uuid}>
                    {resident.firstName} {resident.lastName} -{" "}
                    {resident.location?.name}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </form>
    </FormProvider>
  );
});

export default NedapIntegration;

/* eslint-enable */
